import http from "@/http-common";

class StatisticFinanceV2Service {
    // tải excel thống kê
    downloadFileExcelStatistic(guiId) {
        return http.get('/fn/fees/downloadFileStatistic', {params: {guiId},responseType:"blob"})
    }

    // Xuất file Excel thống kê theo lớp cho các lớp với học sinh chưa hoàn thành hóa đơn
    exportExcelStatistic(classParams){
        return http.get(`/fn/fees/exportExcelStatistic`, {params: classParams})
    }

    // xuất class chưa có hóa đơn
    exportExcelStatisticHaveNotOrder(classParams) {
        return http.get('/fn/fees/exportExcelStatistic/haveNotOrder', {params: classParams})
    }

    // Xuất file Excel thống kê theo lớp cho nhóm với học sinh chưa hoàn thành hóa đơn
    exportExcelStatisticForGroup(groupParams){
        return http.get(`/fn/fees/exportExcelStatisticForGroup`, {params: groupParams})
    }

    // xuất nhóm chưa có hóa đơn
    exportExcelStatisticHaveNotOrderForGroup(groupParams) {
        return http.get('/fn/fees/exportExcelStatisticForGroup/haveNotOrder', {params: groupParams})
    }
// lấy biểu đồ
    getTuitionChart(param){
        return http.get('/fn/fees/tutionChart',{params:{param}})
    }
    getAllFilterStatistic(requestParam) {
        return http.post('/fn/fees/statistic/getAllFilterStatistic', requestParam)
    }
    getStatisticCollectionTurn(requestParam){
        return http.get('/fn/chart/statistic/staticsTurn', {params:requestParam})

    }
    getStatisticOfCollectionTurnForGroup(requestParam){
        return http.get('/fn/chart/statistic/staticsTurnForGroup',{params:requestParam})
    }
    getAllFilterStatisticForGroup(requestParam){
        return http.post('/fn/fees/statistic/getAllFilterStatisticForGroup', requestParam)
    }

    // check học sinh không có trong lớp
    getCheckStudentNotInClass(listIdClass){
        return http.get(`/fn/fees/checkClassNoStudent?listIdClass=${listIdClass}`)
    }
    // check học sinh không có trong nhóm
    getCheckStudentNotInGroup(listIdGroup){
        return http.get(`/fn/fees/checkGroupNoStudent?listIdGroup=${listIdGroup }`)
    }
}

export default new StatisticFinanceV2Service()