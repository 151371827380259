import {styleExcel} from "@/utils/styleExcel";

export default new class DrawExcel {
    // vẽ excel theo thứ tự
    // Đặt vị trí và set chiều rộng cho hàng
    drawColumnWidths(columnWidths, worksheet) {
        columnWidths.forEach((x, index)=>{
            const column = worksheet.getColumn(index + 1);
            column.width = x;
        })
    }

    // Vẽ thông tin info trường hoặc công ty
    drawCompanyInfo(companyInfo, worksheet) {
        companyInfo.forEach(info => {
            const row = worksheet.addRow([info]);
            row.eachCell(cell => {
                cell.font = {...styleExcel.fontSize(), color: {argb: 'FFFF0000'},}
                cell.alignment = {
                    horizontal: 'left',
                    indent: 1 // thụt lề 10px
                };

            });
        });
    }

    // Tiêu đề của file excel
    drawTitleRow(titleFile, worksheet) {
        const titleRow = worksheet.addRow([titleFile]);
        titleRow.eachCell(cell => {
            cell.font = {
                color: {argb: 'FF0000FF'},
                bold: true,
                size: 12
            };
            cell.alignment = {
                horizontal: 'left',
                indent: 1 // thụt lề 10px
            };
        });
    }

    // Nội dung của các cột
    drawTableHeaders(columnHeaders, worksheet) {
        const headerRow = worksheet.addRow(columnHeaders);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'C0C0C0'} // mã màu ARGB
            };
            cell.alignment = {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true // Tự động ngắt dòng
            };
            cell.border = styleExcel.borderTitle();
        });
    }

    // Thêm dữ liệu vào bảng
    drawTable(dataList, worksheet) {
        dataList.forEach((x) => worksheet.addRow(x));
        // Đặt border cho toàn bộ bảng
        for (let i = 7; i <= dataList.length + 6; i++) {
            const row = worksheet.getRow(i);
            row.alignment = {horizontal: 'center', wrapText: true};
            row.eachCell({includeEmpty: true}, (cell) => {
                // Đặt border cho cell
                cell.border = styleExcel.borderTitle();
            });
        }
    }
    // Dành cho các bảng nào có tổng cộng bên dưới bảng
    drawTotalData(otherObject,worksheet){
        const columBottom =  worksheet.addRow(otherObject);
        columBottom.eachCell((cell) => {
            cell.border = styleExcel.borderTitle();
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFF33'} // mã màu ARGB
            };
        })
    }


    drawTotalDataStatistics(otherObject, worksheet) {
        const columBottom = worksheet.addRow(otherObject);

        // Gộp cột 1 và cột 2
        const firstCell = columBottom.getCell(1);
        worksheet.mergeCells(columBottom.number, 1, columBottom.number, 2);

        // Định dạng cho ô đã gộp
        firstCell.alignment = {
            horizontal: 'center', // Căn giữa theo chiều ngang
            vertical: 'middle',   // Căn giữa theo chiều dọc
        };
        firstCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF33' } // mã màu ARGB
        };
        firstCell.font = {
            bold: true, // Đặt chữ đậm
            size: 13    // Cỡ chữ (có thể tùy chỉnh)
        };
        firstCell.border = styleExcel.borderTitle();
        columBottom.eachCell((cell) => {
            cell.border = styleExcel.borderTitle();
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF33' } // mã màu ARGB
            };
            cell.alignment = {
                horizontal: 'center', // Căn giữa theo chiều ngang
                vertical: 'middle'    // Căn giữa theo chiều dọc
            };
            cell.font = {
                bold: true, // Đặt chữ đậm
                size: 13    // Cỡ chữ (có thể tùy chỉnh)
            };
        });
    }
    drawTableStatistics(dataList, worksheet) {
        dataList.forEach((x) => worksheet.addRow(x));
        // Đặt border cho toàn bộ bảng
        for (let i = 4; i <= dataList.length + 3; i++) {
            const row = worksheet.getRow(i);
            row.alignment = {horizontal: 'center', wrapText: true};
            row.eachCell({includeEmpty: true}, (cell) => {
                // Đặt border cho cell
                cell.border = styleExcel.borderTitle();
                cell.font = {
                    size: 13    // Cỡ chữ (có thể tùy chỉnh)
                };
            });
        }
    }

    drawTableHeadersStatistics(columnHeaders, worksheet) {
        const headerRow = worksheet.addRow(columnHeaders);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'C0C0C0'} // mã màu ARGB
            };
            cell.alignment = {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true // Tự động ngắt dòng
            };
            cell.font = {
                bold: true, // Đặt chữ đậm
                size: 13    // Cỡ chữ (có thể tùy chỉnh)
            };
            cell.border = styleExcel.borderTitle();
        });
    }



}

