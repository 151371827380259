export const handleAndDownloadExcel=(excelData, excelFilename)=>{
    if(typeof (excelData)==="string"){
        excelData = new Blob([excelData]);
    }
    // Create a URL object from the Excel data
    const excelUrl = URL.createObjectURL(excelData);

    // Create a link element
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = excelFilename;

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object and remove the link from the document body
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
}