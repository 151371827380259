import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import DrawExcel from "@/commonFuncion/DrawExcel";

export async function exportToExcel(exportToExcelData, otherObject) {

    // Dữ liệu truyền vào từ component
    const titleFile = exportToExcelData.titleFile;
    const columnHeaders = exportToExcelData.columnHeaders;
    const companyInfo = exportToExcelData.companyInfo;
    const columnWidths = exportToExcelData.columnWidths;
    const dataList = exportToExcelData.dataList;
    const fileName = exportToExcelData.nameFile;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName);

    await DrawExcel.drawColumnWidths(columnWidths, worksheet);

    await DrawExcel.drawCompanyInfo(companyInfo, worksheet);
    await DrawExcel.drawTitleRow(titleFile, worksheet);
    worksheet.addRow([]);
    await DrawExcel.drawTableHeaders(columnHeaders, worksheet);
    await DrawExcel.drawTable(dataList, worksheet);

    // Dành cho những file nào có tổng cộng bên dưới (Thống kê học phí theo nhóm, lớp)
    if (fileName.match('ThongKe')) {
     await DrawExcel.drawTotalData(otherObject,worksheet);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');

}

